<template>
    <Loader v-if="loading"/>
    <BaseLayout v-else>
        <template v-slot:page-content>
            <Slider :photos="advertisements"/>
            <Features/>
            <ProductSlider :subHeader="$t('home.most-selling')" :productsList="mostSelling" swiperRef="mostSeller"/>
            <Offers v-if="offers.length" :images="offers"/>
            <ProductSlider :subHeader="$t('home.all-products')" :toggleProduct="true" :productsList="products"
                           swiperRef="allProducts"/>
            <Banner data-aos="fade-up" data-aos-duration="800"/>
            <!--            <AdsModal/>-->
        </template>
    </BaseLayout>
</template>

<script>
import BaseLayout from "@/containers/BaseLayout.vue";
import Slider from "@/views/Home/components/Slider";
import Features from "@/views/Home/components/Features";
import ProductSlider from "@/views/Home/components/ProductSlider";
import Offers from "@/views/Home/components/Offers";
import Banner from "@/views/Home/components/Banner";
import {home} from "@/services/home";
import {getFavourites} from "@/services/products";
import {getCareItems} from "@/services/cart";
import store from "@/store";
import Loader from "@/components/Loader";
// import AdsModal from "@/components/AdsModal";
export default {
    components: {Loader, Banner, Offers, ProductSlider, Features, Slider, BaseLayout},
    data() {
        return {
            advertisements: [],
            offersImages: ['offer1.png', 'offer2.png', 'offer4.png'],
            offers: [],
            loading: false
        }
    },
    computed: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        currency: function () {
            return store.getters.getCurrency;
        },
        products: function () {
            return  store.getters.products.Products
        },
        mostSelling: function () {
            return store.getters.products.more_sales
        }
    },
    mounted() {
        this.setupPage();
        store.dispatch('fetchProducts', this.currency.id)
        // store.dispatch('fetchProducts', this.currency.id)
        if (this.$store.getters.user.loggedIn) {
            this.getFav();
            this.getCart();
        }
    },

    methods: {
        setupPage() {
            this.loading = true;

            home().then(res => {
                this.advertisements = res.data.data.Advertisements;
                // this.products = res.data.data.Products;
                // this.mostSelling = res.data.data.more_sales;
                this.offers = res.data.data.Offers;
                setTimeout(() => this.loading = false, 200)
            })
        },

        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        getFav() {
            getFavourites().then(res => {
                if (res.data.status.code === 401) {
                    localStorage.removeItem('access_token')
                    this.$router.push('/login')
                    this.$store.dispatch("fetchFavourite");
                    this.$store.dispatch("fetchCart");
                }
                store.dispatch("fetchFavourite", res.data.Products);
            })
        },
        getCart() {
            getCareItems().then(res => {
                store.dispatch("fetchCart", res.data.Cart)
            })
        }
    }

}
</script>
