<template>
    <div class="threeimg container-fluid px-lg-4  py-lg-2">
        <div class="product">
            <div class="mr-custom ml-custom">
                <h3 class="my-4 sub-header">{{ $t('home.month-offers') }}</h3>

                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6 mb-4" :class="images.length === 1 ? 'col-md-12' : 'col-md-6' " v-if="images.length > 0" data-aos="fade-right" data-aos-duration="1200">
                            <img :src="APP_URL + images[0]?.image" alt="" class="w-100 h-100 offer-img">
                        </div>
                        <div class="col-md-6 mb-4" v-if="images.length === 2" data-aos="fade-left" data-aos-duration="1200">
                            <img :src="APP_URL + images[1]?.image" alt="" class="w-100 h-100 offer-img">
                        </div>

                        <div class="col-sm-12" v-if="images.length === 3" data-aos="fade-up" data-aos-duration="1400">
                            <img :src="APP_URL + images[2]?.image" alt="" class="w-100 offer-img">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Offers',
        props:['images'],
        computed:{
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            APP_URL: function () {
                return process.env.VUE_APP_REMOTE_URL
            },
        }
    }
</script>

<style>
.offer-img {
    width: 100%;
    height: 100%;
    max-height: 450px;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
    border: 1px solid rgba(190 ,190 ,190 ,.5);
}
</style>