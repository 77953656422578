<template>
  <!-- boxs  -->
  <div class="features">
    <div class="container-fluid px-lg-4 text-center ">
      <div class="ml-custom mr-custom">
          <div class="row">
              <FeatureCard data-aos="fade-up" data-aos-delay="200" data-aos-duration="400" icon="headphones.png" :title="$t('home.help')" :desc="$t('home.help-desc')"/>
              <FeatureCard data-aos="fade-up" data-aos-delay="200" data-aos-duration="800" icon="payment.png" :title="$t('home.payment-accepted')" :desc="$t('home.payment-desc')"/>
              <FeatureCard data-aos="fade-up" data-aos-delay="200" data-aos-duration="1200" icon="verified.png" :title="$t('home.quality')" :desc="$t('home.quality-desc')"/>
              <FeatureCard data-aos="fade-up" data-aos-delay="200" data-aos-duration="1600" icon="004-shipped.png" :title="$t('home.products-delivery')" :desc="$t('home.delivery-desc')"/>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeatureCard from "@/views/Home/components/FeatureCard";
export default {
  name: "Features",
  components: {FeatureCard}
}
</script>

<style scoped>

</style>