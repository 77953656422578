<template>
    <div class="apps container-fluid px-lg-4">
        <div class="mr-custom ml-custom">
            <div class="container-fluid">
            <div class="row position-relative">
                <div class="col-md-12">
                    <img src="@/assets/img/bannerBg.png" alt="" class="w-100">
                </div>
                <div class="main">
                    <h4> {{$t('home.get-the-app')}} </h4>
                    <p>
                        {{$t('home.get-app-heading')}}
                        <br>
                        {{$t('home.get-app-desc')}}
                    </p>
                    <a :href="settings.app_store_link"  class="mx-2">
                        <img src="@/assets/img/store.png" alt="">
                    </a>
                    <a :href="settings.play_store_link">
                        <img src="@/assets/img/play.png" alt="">
                    </a>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'Banner',
    computed: {
        ...mapGetters(['settings'])
    },
}
</script>