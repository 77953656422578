<template>
    <!-- /* Start Carousel  */ -->
    <div class=" px-lg-4 main-slider container-fluid rounded">
        <div id="carouselExampleIndicators" class="carousel mr-custom ml-custom slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" :class="{'active': index === 0}"
                    v-for="(photo,index) in photos" :key="index" :data-slide-to="index"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item" v-for="photo in photos" :key="photo.id" :class="{'active': photo.id === 1}">
                    <img class="d-block w-100" :src=" photo.image" :alt="photo.name"/>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true">
                    <i class="fas fa-chevron-left"></i>
                </span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true">
                   <i class="fas fa-chevron-right"></i>
                </span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
    <!-- /* End Carousel */ -->
</template>

<script>
export default {
    name: 'Slider',
    props: ['photos'],
}
</script>

<style>
.carousel-item img {
    object-fit: contain;
    object-position: center;
    height: 400px;
    border: 1px solid #e8e8e8;
}

.main-slider .carousel-indicators li {
    border: 1px solid #e8e8e8;
    background-color: #b4b4b4;
}

@media (max-width: 876px) {
    .carousel-item img {
        height: 350px;
    }
}
</style>