<template>
  <div class="col-lg-3 col-md-4 col-6">
      <div class="boxs">
          <img :src="require('@/assets/img/' + icon+ '')" alt="">
          <h2>{{ title }}</h2>
          <p>{{ desc }}</p>
      </div>
  </div>
</template>

<script>
export default {
  name: "FeatureCard",
  props: ['icon','title','desc']
}
</script>

<style scoped>

</style>